<template lang="pug">
  el-dialog.entity-created-dialog(:visible.sync="visible" :title="'Что дальше?'")
    el-button-group.entity-created-dialog__btn-group
      router-link.entity-created-dialog__link(:to="computedListUrl")
        el-button.entity-created-dialog__link-btn К списку
      router-link.entity-created-dialog__link(:to="lastCreatedLink")
        el-button.entity-created-dialog__link-btn(type="primary") К созданной записи
    el-button-group.entity-created-dialog__btn-group
      el-button.entity-created-dialog__btn(
        type="warning"
        @click="resetAndHide()"
      ) Продолжить, очистить поля
      el-button.entity-created-dialog__btn(
        type="success"
        @click="hide()"
      ) Продолжить
</template>

<script>
  export default {
    name: 'EntityCreateCreatedDialog',
    components: {
    },
    props: {
      createdId: { type: Number, default: null },
      listUrl: { type: String, default: null }
    },
    data() {
      return {
        visible: false
      }
    },
    computed: {
      computedListUrl() {
        return this.listUrl || this.$route.fullPath.replace('/create', '/list')
      },
      lastCreatedLink() {
        return this.$route.fullPath.replace('/create', `/edit/${this.createdId}`)
      },
      lang() {
        return this.$store.getters.language
      },
    },
    async created() {
    },
    methods: {
      show() {
        this.visible = true
      },
      hide() {
        this.visible = false
      },
      resetAndHide() {
        this.$emit('reset')
        this.hide()
      }
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  .entity-created-dialog
    &__btn-group
      width: 100%
      &:not(:last-child)
        margin-bottom: 10px
    &__link
      width: 50%
    &__link-btn
      width: 50%
    &__btn
      width: 50%
</style>
