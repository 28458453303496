<template lang="pug">
  actions-line
    template(v-slot:left)
      router-link.item(:to="listUrl")
        el-button(
          type="primary"
          icon="el-icon-back"
          v-loading="loading"
          :disabled="loading"
        ) К списку
    template(v-slot:right)
      el-button.item(
        type="success"
        v-loading="loading"
        :disabled="loading"
        @click="emitCreate()"
      ) Создать
</template>

<script>
  import ActionsLine from '@/components/PagePartials/ActionsLine'

  export default {
    name: 'EntityCreateActionsLine',
    components: {
      ActionsLine
    },
    props: {
      loading: { type: Boolean, default: false }
    },
    computed: {
      listUrl() {
        return this.$route.fullPath.replace('/create', '/list')
      }
    },
    async created() {
    },
    methods: {
      emitCreate() {
        this.$emit('create')
      }
    },
  }
</script>
