<template lang="pug">
  .page.user-create-page
    actions-line(:loading="loading || passwordGenerating" @create="submitForm()")
    created-dialog(ref="createdDialog" :created-id="lastCreatedId" :list-url="listUrl" @reset="reset()")

    el-form.page__container(label-width="200px")
      .page__body
        el-form-item(label="Роль" v-loading="dataLoading" required v-permission="['manage admin__roles']")
          el-select(v-model="postForm.role" class="filter-item" placeholder="Выберите роль" :readonly="isReadonly")
            el-option(v-for="item in roles" :key="item.id" :label="item.name | uppercaseFirst" :value="item.id")

        el-form-item(label="Имя" required)
          el-input.user-create-page__input(v-model="postForm.name" :readonly="isReadonly")

        el-form-item(label="E-mail" required)
          el-input.user-create-page__input(v-model="postForm.email" :readonly="isReadonly")

        el-form-item(label="Срок действия пароля" required)
          el-date-picker(type="datetime" v-model="postForm.password_expiration_date" value-format="yyyy-MM-dd HH:mm:ss" :readonly="isReadonly")
          .user-create-page__time-left(v-if="timeLeftBeforeExpire > 0") До окончания действия пароля осталось {{ formattedTimeLeftBeforeExpire }}
          .user-create-page__time-left.user-profile__time-left_error(v-if="postForm.password_expiration_date && timeLeftBeforeExpire <= 0") Пароль не действует. Задайте новый срок действия пароля.

        el-form-item(label="Заблокирован" required)
          el-switch(v-model="postForm.blocked")

        el-form-item(label="Сменить пароль при первом входе" required)
          el-switch(v-model="postForm.temp_password")

        .user-create-page__passwords(v-loading="passwordGenerating")
          el-form-item(label="Пароль" required)
            el-input.user-create-page__input(ref="password" v-model="postForm.password" show-password :readonly="isReadonly")
            el-button.user-create-page__generate-password-button(@click="generatePassword" type="primary") Сгенерировать пароль

          el-form-item(label="Подтвердите пароль" required)
            el-input.user-create-page__input(v-model="postForm.confirmPassword" show-password :readonly="isReadonly")

        div(v-permission="['manage admin__permissions']")
          h4.condition-field__wide-title Разрешения
          .user-create-page__permissions-wrapper(v-loading="!permissionsTree.length")
            user-permissions(v-if="permissionsTree.length" v-model="postForm.permissions" :role="postForm.role" :permissionsTree="permissionsTree")

</template>
<script>
  import SaveRoutes from '@/views/Templates/SaveRoutes'

  import { notifyErrorRequest, notifyRequest } from '@/utils/api'

  import UserPermissions from './UserPermissions'
  import CreatedDialog from '@/components/PagePartials/EntityCreate/CreatedDialog'
  import ActionsLine from '@/components/PagePartials/EntityCreate/ActionsLine'
  import permission from '@/directive/permission'

  export default {
    name: 'CreateUser',
    extends: [SaveRoutes],
    directives: { permission },
    components: {
      UserPermissions,
      CreatedDialog,
      ActionsLine
    },
    data() {
      return {
        roles: [],
        permissionsTree: [],
        postForm: {
          role: null,
          name: '',
          email: '',
          password_expiration_date: '',
          password: '',
          blocked: false,
          temp_password: true,
          passwordConfirm: '',
          permissions: []
        },
        request: this.$route.meta.request,
        loading: true,
        dataLoading: true,
        fields: [],
        rules: {
        },
        fieldsHidden: false,
        lastCreatedId: 0,
        passwordGenerating: false,
        isReadonly: true
      }
    },
    mounted() {
      setTimeout(() => {
        this.isReadonly = false
      }, 500)
    },
    computed: {
      timeLeftBeforeExpire() {
        const date = new Date(this.postForm.password_expiration_date)
        return date.getTime() - Date.now()
      },
      formattedTimeLeftBeforeExpire() {
        const ms = this.timeLeftBeforeExpire
        const hours = Math.floor(ms / 1000 / 3600)
        if (hours > 48) {
          const days = Math.floor(hours / 24)
          return `дней: ${days}`
        }
        return `часов: ${hours}`
      },
      listUrl() {
        return this.$route.fullPath.replace('/create', '')
      },
      lang() {
        return this.$store.getters.language
      },
      rolePermissions() {
        const role = this.roles.filter(el => el.id === this.postForm.role)[0]
        if (!role) {
          return []
        }
        const permissions = role.permissions
        if (!permissions || !Array.isArray(permissions)) {
          return []
        }
        return permissions.map(el => el.name)
      }
    },
    async created() {
      const fetchedData = await this.fetchData()
      this.roles = fetchedData.roles
      this.permissionsTree = fetchedData.permissionsTree
      this.loading = false
      this.dataLoading = false
    },
    methods: {
      reset() {
        this.postForm = {
          role: null,
          name: null,
          email: null,
          blocked: false,
          password_expiration_date: null,
          password: null,
          confirmPassword: null,
          permissions: null
        }
      },
      async generatePassword() {
        this.passwordGenerating = true
        this.postForm.password = 'Генерация пароля'
        this.postForm.confirmPassword = 'Генерация пароля'
        const response = await notifyRequest({
          url: 'generate-password',
          method: 'get'
        }, 'Пароль успешно сгенерирован и вставлен')
        const password = response.password
        this.postForm.password = password
        this.postForm.confirmPassword = password
        this.$refs.password.passwordVisible = true
        this.passwordGenerating = false
      },
      async fetchData() {
        return await notifyErrorRequest({
          url: `users/create`,
          method: 'get'
        })
      },
      async submitForm() {
        this.loading = true
        const responseData = await notifyRequest({
          url: 'users',
          method: 'post',
          data: this.postForm
        }, 'Пользователь успешно создан')
        if (!responseData.errors) {
          // console.log(responseData);
          this.lastCreatedId = responseData.data.id
          this.$refs.createdDialog.show()
        }
        this.loading = false
      },
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
  .user-create-page
    &__input
      max-width: 400px
    &__generate-password-button
      margin-left: 15px
    &__time-left
      &_error
        color: red
        font-weight: 700
</style>
